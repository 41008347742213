<template>
  <div class="guide-cnt16">
    <h6 class="h6">常见问题</h6>
    <div class="cnt">
      <p class="p"><span>Q</span> 有起订量的限制吗？如果我要印的量比较少，也可以吗？</p>
      <p class="p pm"><span>A</span> 各类产品我们规定了一个基本的起订量，具体数目可以参照自助询价中最低一档的数量。</p>
      <p class="p"><span>Q</span> 提交订单之后我临时变动，需要取消订单，可以吗？</p>
      <p class="p pm"><span>A</span> 您提交订单后如果需要取消或者修改，请先看一下订单状态，在我们还没有确认接受您的订单之前，您是可以取消或者修改的，如果您的订单状态已经改变，我们已经接受订印了，就不能取消和修改了。</p>
      <p class="p"><span>Q</span> 请问若是在印刷的纸材上没有什么概念，不知道该选择什么纸材去印刷的话，要怎么办呢？</p>
      <p class="p pm"><span>A</span> 一般客户对印刷的纸材都没有很了解，您可以参考各个纸材图片，就可以看到该纸材适用的情形，您就可以比较看看哪一种纸材适合您们公司了！若还是没概念，我们可以帮您做建议。</p>
      <p class="p"><span>Q</span> 为什么你们用的纸张和我们以前印刷的纸张不同？</p>
      <p class="p pm"><span>A</span> 这是因为市面上常用的纸张都有十几个品牌，每个品牌又分几个系列，就是同一克重的纸张，不同的厂家生产，同一纸厂不同系列的纸张，色泽，亮度，厚度，都会略有差别的，我们一般选用的是中上等级别的纸，质量敬请放心，做为常用的广告消耗品，已经足够了。</p>
      <p class="p"><span>Q</span> 为什么现在印刷的色彩和我以前印刷的色彩有差别？</p>
      <p class="p pm"><span>A</span> 这是因为现在的印刷机都是微电脑控制的，调色都是用控制面板的按键来完成，每位印刷机长在调色时，都是按正常的色位和自己的印刷经验来印刷，这样都会造成油墨供给上细微的差别，这个属正常现象。一般只要源文件没有改变，不会差别太大，不过，如果您对色彩要求极为严格的话，最好快递个样本给我们，跟着色样来印，会比较接近些。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt16',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt16 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
      span {
        background: #61baff;border-radius: 50%;width: 20px;height: 20px;display: inline-block;line-height: 20px;text-align: center;color:#fff;font-size: 12px;
      }
    }
    .pm {
      margin-bottom:30px;
      span {
        background: #f44e61;
      }
    }
  }
}
</style>
